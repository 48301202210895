.c-footer {
  /* i am positioned as */
  //grid-area: footer;
  //position: sticky;
  //top: 100vh;
  //width: 100%;
  padding-bottom: 2rem;
  /* but define at myself */
  background-color: $secondary-color;
  color: white;
  @include breakpoint(medium down) {
    position: relative;
    display: block;
    //bottom: 0;
  }
}

.c-footer__cell {
  & > h3 {
    margin: 1rem 0;
    color: white;
  }
}

.c-footer__link {
  color: $primary-color;
}

.n-footer {
  display: block;
}