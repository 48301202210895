ul, .c-unordered-list {
  isolation: isolate;
  margin: 0;
  padding: 0;
  list-style-type: none;


  & li {
    position: relative;
    width: min(70ch, 100%);
    margin-left: .75em;
    margin-bottom: .5em;
    transition: background-color 200ms ease-in,
    color 200ms ease-out;
    /*&:hover {
      background-color: $secondary-color;
      color: white;
    }*/

    &::before {
      position: absolute;
      margin-left: -.75em;
      color: $primary-color;
      content: "» "
    }
  }
}

.c-list-item {
  position: relative;

  &--break {
    break-before: column;
  }
}