.c-announcement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: rgba($secondary-color, 0.8);
  z-index: -1;

  & a {
    display: block;
    width: 100%;
    font-weight: bold;
    color: white;
    text-shadow: $secondary-color .1rem .1rem .05rem;
    text-align: center;
  }

  > a {
    color: $secondary-color;
  }

  @include breakpoint(small only) {
    align-items: start;
  }
}

.c-announcement__card {
  width: 40vw;
  background: $primary-color;
  border-radius: 10px;
  color: white;
  box-shadow: $secondary-color .5rem .5rem 1rem;
  padding: 1rem;
  @include breakpoint(medium down) {
    margin-top: 6rem;
    width: 90vw;
  }
}

.c-announcement__card-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-announcement__card-headline {
  margin-right: auto;
}

.c-header__button {
  &--announcement {
    cursor: pointer;
    background-color: $primary-color;
    color: white;
    animation: pulsate 2.5s ease-in-out infinite;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(100%)
  }
  50% {
    transform: scale(105%)
  }
  100% {
    transform: scale(100%)
  }
}

.c-announcement__image {
  @include default-image-border();
}

.c-announcement__link {
  text-align: center;
}

.c-announcement__button {
  cursor: pointer;
  color: $secondary-color;
}

.c-announcement__headline {
  text-shadow: white .05rem .05rem .05rem;
}
