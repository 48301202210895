.n-mobile-menu {
  display: flex;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba($secondary-color, .9);
  align-items: center;
  justify-content: center;
}

.n-mobile-menu__container {
  position: relative;
  display: flex;
  flex-direction: column;
  @include breakpoint(small only) {
    margin: 0 1rem;
  }
}

.n-mobile-menu__item {
  background-color: rgba(white, 0.3);
  font-weight: bold;
  font-size: 2rem;
  margin: .5rem 0;
  padding: .5rem 1rem;
  border-radius: .5rem;
  transition: box-shadow 200ms ease-in-out;
  border: 1px solid transparent;
  @include breakpoint(small only) {
    margin: .25rem 0;
    padding: .25rem .5rem;
  }

  &:hover {
    border: 1px solid white;
    @include breakpoint (small only) {
      box-shadow: none;
    }
  }

  & a {
    display: block;
    width: 100%;
    color: white;
    //text-shadow: $primary-color .1rem .1rem .025rem;
  }

  @include breakpoint(small only) {
    font-size: 1.25rem;
  }
}

.n-mobile-menu__button {
  position: absolute;
  cursor: pointer;
  color: white;
  padding: 3rem;
  right: 1rem;
  top: 1rem;
  @include breakpoint(small only) {
    padding: 1rem;
  }
}