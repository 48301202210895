.n-login,
.n-download,
.n-socialmedia,
.n-mobilemenu{
  position: relative;
  margin-right: 1.5rem;
  text-align: center;
  font-size: 0.8rem;
  text-decoration: none;
  color: rgba($secondary-color,.5);
  transition:
          font-size 300ms ease-in-out,
          color 250ms ease-in-out,
          filter 300ms ease-in-out;
  @include breakpoint (medium down) {
    font-size: 0.7rem;
    margin-right: 1.2rem;
  }

  &:hover {
    color: $secondary-color;
    filter: drop-shadow( .1rem .1rem .1rem rgba($secondary-color,.5));
    &::after {
      color: $primary-color;
    }
  }
  &::after {
    position: relative;
    display: block;
    margin-top: 0.2em;
    content: attr(data-fulltext);
    transition: color 250ms ease-in-out 100ms;
  }
}

/**
 * not yet usable, follows soon
 */
.n-download, .n-socialmedia--instagram, .n-login  {
  display: none;
}