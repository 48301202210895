.c-claim {
  // important to get a position context!
  position: relative;
  width: 100%;
}

.c-claim__title {
  isolation: isolate;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  grid-column: 2 / -1;
  font-family: $header-font-family;
  font-size: var(--var-claim-header-size);
  font-weight: bold;
  color: white;
  text-shadow: $secondary-color 0.1rem 0.1rem;
  margin: 1em 0 .5em 0;
  line-height: 1.1em;
  z-index: 1;

  @include breakpoint(small only) {
    --var-claim-header-size: 2rem;
  }

  &::before {
    color: $primary-color;
    position: absolute;
    content: "»";
    z-index: -1;
    font-size: 2.5em;
    top: .1em;
    left: -.2em;
  }

  &--inverse {
    color: $secondary-color;
    text-shadow: white 0.1rem 0.1rem;
  }

  &--small {
    --var-claim-header-size: 3rem;
    @include breakpoint(small only) {
      --var-claim-header-size: 1rem;
    }
  }

  &--orange {
    color: $primary-color;
    text-shadow: $secondary-color 0.1rem 0.1rem;

    &::before {
      color: white;
    }
  }

  @include breakpoint(small only) {
    --var-claim-header-size: 3rem;
    margin: .1em 0 .5em 0;
  }
}

.c-claim__author {
  font-size: .5em;
}

.c-claim__copytext {
  background-color: rgba(#fff, 0.85);
  position: relative;
  padding: var(--var-default-gap);
  border-radius: .5rem;
  margin-bottom: 4em;
  width: min(100%, 70ch);
  column-count: 1;
  column-gap: 2rem;
  font-style: italic;
  font-size: 1.3em;
  font-weight: 600;
  //transform: perspective(600px) rotateY(-5deg);
  //transition: transform 250ms ease-in;

  &--speech-bubble {
    &::before {
      content: "";
      position: absolute;
      top: 10%;
      left: -25px;
      height: 15px;
      width: 25px;
      background: inherit;
      //clip-path: polygon(0 0, 100% 0, 50% 100%);
      clip-path: polygon(100% 0, 100% 100%, 0% 100%);
    }
  }

  & p:first-of-type {
    margin-top: 0;
  }
}

.c-claim__image-container {
  position: sticky;
  top: .5rem;
  align-self: start;
  margin-inline: auto;
  //transform: translateY(-10.5rem);
  transform: translateY(-10.5rem) perspective(600px) rotateY(15deg);
  transition: transform 250ms ease-in-out 50ms;
  z-index: 0;

  &:hover {
    transform: translateY(-10.5rem) perspective(600px) rotateY(0deg);

    & > .c-claim__image {
      border-radius: 1rem 1rem 0 0;
    }

    & > .c-claim__image-caption {
      opacity: 1;
    }
  }

  @include breakpoint(medium down) {
    position: relative;
    top: inherit;
    margin: auto;
    transform: none;
  }
  @include breakpoint(small only) {
    max-width: 75%;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.c-claim__image {
  position: relative;
  object-fit: cover;
  width: 100%;
  transition: border-radius 250ms linear;
  //max-height: 100%;
  @include default-image-border(top);
  border-radius: 1rem;
  @include breakpoint(medium down) {
    max-height: 50%;
  }
}

.c-claim__image-caption {
  opacity: 0;
  transition: opacity 250ms ease-in;
  @include default-cell-background(bottom);
  font-size: 0.8rem;
  text-align: center;
  @include breakpoint(small only) {
    margin: auto;
  }
}