.c-goals__container {
  margin-top: 1rem;
}

.c-goals {
  position: sticky;
  top: 0;
  grid-column: 1 / span 4;
  background-color: silver;
  border-radius: 1rem;
  align-self: start;
  @include breakpoint (small down) {
    position: relative;
    top: inherit;
  }
  @include breakpoint (small only) {
    margin-bottom: 1rem;
  }
}

.c-goals__title {
  background-color: $secondary-color;
  text-align: center;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.c-goals__symbol {
  margin-right: .5rem;
  color: $primary-color;
}

.c-goals__copytext {
  padding: 1rem;
  font-style: italic;
  font-weight: 600;
  color: $secondary-color;

  & > p {
    margin-top: 0;
  }
}

.c-target {
  @include default-cell-background();
  align-items: start;
}

.c-target__title {
  padding: 0.5rem 0;
}