h1,
h2,
h3,
h4 {
  color: $secondary-color;
  font-family: $header-font-family;
}

h1 {
  font-size: 3rem;
  font-style: normal;
  font-weight: bold;
  @include breakpoint(small only) {
    font-size: 2.2rem;
  }
}

h2 {
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 1.3rem;
  font-style: normal;
  font-weight: bold;
  margin: 0.25rem 0 0 0;
  padding: 0;
}

.c-header__primary {
  padding: 0.5em 0 .3em 0;
}

.c-color__secondary {
  color: $secondary_color;
}

.c-color__primary {
  color: $primary-color;
}