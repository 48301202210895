p {
  margin-top: .5rem;
  margin-bottom: .75rem;
}

.c-paragraph--small {
  font-size: 0.8rem;
}

a {
  color: $primary-color;
  transition: color 250ms ease-in-out;

  &:hover {
    color: $secondary-color;
  }
}

.c-paragraph__download {
  display: inline-block;
  padding: .5rem;
  background-color: $secondary-color;
  border-radius: .5rem;
  transition: color 250ms ease-in-out;
  & > a {
    display: block;
  }
  &:hover {
    background-color: $primary-color;
  }
}