.c-steering-committee {
  position: relative;
  padding: 4rem 0 0rem 0;
  background-image: url(../images/background/comfort-zone-fish-and-hand.png);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  @include breakpoint (small only) {
    background-size: contain;
    background-repeat: repeat-y;
    background-attachment: unset;
  }
}
