.c-news__container {
  width: 100%;
}
.c-news__card {
  position: sticky;
  top: 10rem;
  margin-top: 1rem;
  @include default-cell-background();
  @include breakpoint (small only) {
    margin-bottom: 1rem;
  }
}

.c-news__card_title {
  text-align: center;
}

.c-news__card-copytext {
  text-align: center;
  color: $primary-color;
  font-weight: bold;
}

.c-news__calendar-symbol {
  margin-right: 0.5rem;
  color: $primary-color;
}