@mixin default-cell-background($position: 'isolated', $padding: '', $blur: 0) {
  background-color: $default-cell-background;
  border-radius: 0.5rem;
  padding: 1rem;
  @if $blur > 0 {
    backdrop-filter: blur($blur);
  }
  @if $position == 'top' {
    border-radius: .5rem .5rem 0 0;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0;
  }
  @if $position == 'middle' {
    border-radius: 0;
    padding: 0 1rem 0 1rem;
  }
  @if $position == 'bottom' {
    border-radius: 0 0 .5rem .5rem;
    padding: 1rem 1rem 1rem 1rem;
  }
  @if $padding == 'all' {
    padding: 1rem;
  }
}

@mixin default-cell-padding() {
  padding: 1rem;
}

@mixin default-image-border($position: 'isolated') {
  border-radius: 1rem;
  @if $position == 'top' {
    border-radius: 1rem 1rem 0 0;
  }
  @if $position == 'middle' {
    border-radius: 0;
  }
  @if $position == 'bottom' {
    border-radius: 0 0 1rem 1rem;
  }
}