
@import '../../node_modules/motion-ui/src/motion-ui';

@include motion-ui-transitions;
@include motion-ui-animations;
@import '../../node_modules/foundation-sites/scss/foundation';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
//@include foundation-grid;
//@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
//@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

@import "reset";
@import "settings";

@import "abstracts/mixins";

@import "typography/google-fonts";
@import "typography/headings";
@import "typography/paragraphs";

@import "layout/content-container";
@import "layout/grid-container";
@import "layout/cell";
@import "layout/icons";
@import "layout/images";

@import "components/placeholder";

@import "components/secondary-navigation";
@import "components/mobile-menu";
@import "components/header";
@import "components/lists";
@import "components/fields-of-action";
@import "components/steering-commitee";
@import "components/testimonial";
@import "components/claim";
@import "components/greeting";
@import "components/leadership";
@import "components/announcements";
@import "components/news";
@import "components/goals";
@import "components/assets";
@import "components/footer";

:root {
  font-size: 16px;
  font-family: $body-font-family;
  --var-default-gap: 1rem;
  --var-header-font-color: $secondary-color;
  --var-body-font-family: $body-font-family;
  --var-base-line-height: 1.4;
  --var-base-font-color: #626262;

  --var-claim-header-size: 4rem;
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 15px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font-family);
  color: var(--var-base-font-color);
  line-height: var(--var-base-line-height);
}
