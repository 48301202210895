.c-assets__container {
  margin-top: 1rem;
  width: 100%;
}

.c-assets {
  background-color: $white;
  border-radius: 1rem;
  @include breakpoint (small down) {
    position: relative;
    top: inherit;
  }
  @include breakpoint (small only) {
    margin-bottom: 1rem;
  }
}

.c-assets__title {
  background-color: $primary-color;
  text-align: center;
  color: $secondary-color;
  padding: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.c-assets__symbol {
  margin-right: .5rem;
  color: $secondary-color;
}

.c-assets__copytext {
  padding: 1rem;
  font-style: italic;
  font-weight: 600;
  color: $secondary-color;

  & > p {
    margin-top: 0;
  }
}