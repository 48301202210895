.c-placeholder__body{
  background-image: url(../images/background/jumping-fish.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  @include breakpoint (small only) {
    background-attachment: fixed;
    background-size: 290%;
    background-position: center center
  }
}

.c-placeholder {
  height: calc(80vh - 6rem);
  @include breakpoint(small only) {
    height: calc(100vh - 6rem);
  }
}

.c-placeholder__cell {
  @include default-cell-background();
}

.c-headline {
  font-family: $header-font-family;
  font-size: 4rem;
  font-weight: bold;
  color: $secondary-color;
  text-shadow: $white .1rem .1rem;
  text-align: center;
}

.c-counter {
  margin-top: 2rem;
  text-align: center;
  font-family: $header-font-family;
  font-size: 2.2rem;
  font-weight: bold;
  color: $secondary-color;
  text-shadow: $white .05rem .05rem;
}