.c-greeting {
  order: 1;
  @include breakpoint(small only) {
    order: 2;
  }
}

.c-greeting__title {
  @include default-cell-background(top, all);
}

.c-greeting__subtitle {
  @include default-cell-background(middle);
}

.c-greeting__copytext {
  @include default-cell-background(bottom);
  //column-count: 2;
  //column-gap: 1rem;
  & p:first-of-type {
    margin-top: 0;
  }
}

.c-image-container--greeting {
  order: 2;
  position: sticky;
  top: .5rem;
  align-self: start;
  width: 40vw;
  margin-inline: auto;
  @include breakpoint(medium down) {
    position: relative;
    top: inherit;
    margin: auto;
  }
  @include breakpoint(small only) {
    order: 1;
    width: 10vw;
    margin-bottom: 1rem;
    text-align: center;
  }
}