/*.l-grid-container {
  position: relative;
  display: grid;

  &--foa {
    margin-bottom: 3rem;
  }

  @include breakpoint(medium up) {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;
    colum-gap: 1rem;
    margin-bottom: 1rem;
  }

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
    grid-auto-flow: initial;
    row-gap: 1rem;
    margin-bottom: 1rem;
  }
}*/

.l-grid__container {
  position: relative;
  &--justify-center {
    justify-content: center;
  }
}

// old versions maybe we do it some day
/*
@media only screen and (min-width: 901px) {
  .l-grid-container {
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .l-grid-container {
    grid-template-columns: 1fr;
    grid-auto-flow: initial;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

.l-section-container {
  position: relative;
  display: grid;
  min-width: 300px;
  max-width: 1280px;
  margin: auto;
  gap: 1rem;

  &--fullwidth {
    width: 100%;
  }

  grid-template-columns: repeat(4, 1fr);
}
*/