.c-fields-of-action__container {
  position: relative;
  display: flex;
  height: calc(100vh - 6rem);

  //this is a new feature solving the vh bug within iOS Safari by following!
  //the w3c specs on vh, svh and dvh (see last comment on:
  // https://bugs.webkit.org/show_bug.cgi?id=141832)
  height: calc(100svh - 6rem);


  @include breakpoint(medium down) {
    position: relative;
    display: block;
    height: auto;
    width: 100%;
  }

}

.c-field-of-action {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  box-shadow: rgba($secondary-color, .5) -1rem 0 1rem;

  @include breakpoint(medium down) {
    box-shadow: rgba($secondary-color, .5) 0 -1rem 1rem;
    & .c-field-of-action__headline {
      &::before {
        display: inherit;
      }
    }
  }

  //save some resources, don't box shadow the first one
  &:nth-child(1) {
    box-shadow: none;
  }

  //set background images
  &--home {
    /*background-position: 140% 50%;
    background-size: 120%;*/
    background-image: url(../images/background/home-sailing-fish.jpg);
  }

  &--structures {
    background-image: url(../images/background/structures.jpg);
  }

  &--services {
    background-image: url(../images/background/services.jpg);
  }

  &--personal {
    background-image: url(../images/background/personal.jpg);
  }

  &--finance {
    background-image: url(../images/background/finance.jpg);
  }

  &--contents {
    background-image: url(../images/background/contents.jpg);
    background-blend-mode: darken;
  }

  &--places {
    background-image: url(../images/background/places.jpg);
  }

  &--volunteering {
    background-image: url(../images/background/volunteering.jpg);
  }

  &--news {
    background-image: url(../images/background/news.jpg);
  }

  &:hover {
    // not using it at the moment out of performance reasons
    //flex: 2 0 0;
  }

  /* experimental feature for larger screens
   * adding focus to work with
   */
/*
  @include breakpoint(medium up) {
    &:focus {
      cursor: default;
      flex: 0 0 70%;
      overflow-y: auto;
      background-attachment: fixed;
      transition: all 400ms;

      @include breakpoint(medium down) {
        display: block;
      }

      & .c-field-of-action__headline {
        writing-mode: horizontal-tb;
        height: auto;
        width: 100%;
        text-align: center;
        margin-top: 1em;
        padding: 0.5em;
        background-color: rgba(#fff, 0.85);

        &:hover {
          text-shadow: inherit;
          background-color: rgba(#fff, 0.85);
        }

        &::before {
          display: none;
        }
      }
    }
    & .c-field-of-action__content-container {
      display: grid;
    }

    & .c-field-of-action__copytext {
      visibility: visible;
      display: block;
    }
  }
*/

  &--focus {
    cursor: default;
    flex: 0 0 70%;
    overflow-y: auto;
    background-attachment: fixed;
    transition: all 400ms;

    // TODO: rework this point maybe fixed versions with before or after will work
    @include breakpoint(medium down) {
      display: block;
      background-size: 350%;
      background-position: top center;
      background-attachment: fixed;
      background-repeat: repeat-y;
    }

    & .c-field-of-action__headline {
      writing-mode: horizontal-tb;
      height: auto;
      width: 100%;
      text-align: center;
      margin-top: 1em;
      padding: 0.5em;
      background-color: rgba(#fff, 0.85);

      &:hover {
        text-shadow: inherit;
        background-color: rgba(#fff, 0.85);
      }

      &::before {
        display: none;
      }
    }

    & .c-field-of-action__content-container {
      display: grid;
    }

    & .c-field-of-action__copytext {
      visibility: visible;
      display: block;
    }
  }

}

/**
   * headline definitions
   */
.c-field-of-action__headline {
  display: inline-block;
  position: relative;
  font-family: $header-font-family;
  writing-mode: vertical-lr;
  z-index: 2;
  height: 100%;
  text-align: right;
  padding: 0 0 1rem 0;

  color: $secondary-color;
  background-color: rgba(#ffffff, 0.65);
  transition: background-color 200ms ease-out 200ms,
  text-shadow 500ms ease-out 300ms;

  @include breakpoint(medium down) {
    writing-mode: horizontal-tb;
    text-align: left;
    width: 100%;
    line-height: 1.2;
    height: auto;
    padding: .8rem 0 0 1rem;
    background-color: rgba(#fff, 1);
  }

  &:hover {
    text-shadow: -.05rem .05rem .05rem rgba($secondary-color, .5);
  }

  &:hover::before {
    height: 100%;
    @include breakpoint(medium down) {
      height: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    height: 0;
    width: 1.4em;
    //background-color: rgba(#fff, 0.8);
    background-color: rgba($primary-color, 1);
    transition: height 400ms ease-in;

    @include breakpoint(medium down) {
      content: '';
      position: absolute;
      z-index: 1;
      top: .2em;
      left: 0;
      width: 100%;
      border: 1px solid $primary-color;
    }
  }

  &::after {
    content: '';
    position: absolute;
    background-color: rgba(#fff, 0.65);
    z-index: -1;
    right: calc(100vw) * -1;
    top: 0;
    bottom: 0;
    left: 0;
    @include breakpoint(medium down) {
      content: '';
      position: absolute;
      background-color: rgba(#fff, 1);
      z-index: -1;
      right: 0;
      top: 0;
      left: 0;
      bottom: -.05rem;
    }
  }
}

.c-field-of-action__content-container {
  display: none;
  width: min(100% - 4rem, 1024px);
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.4rem;
  padding: var(--var-default-gap);
  margin-inline: auto;
  @include breakpoint(medium down) {
    width: 100%;
  }
}

.c-field-of-action__copytext {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  font-family: $body-font-family;
  background-color: $default-cell-background;
  text-align: left;
  color: $secondary-color;
}

.c-field-of-action__greeting {
  position: relative;
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: (1fr 3fr);
  align-items: start;
}

.c-field-of-action__portrait {
  position: sticky;
  top: 10rem;
  object-fit: cover;
  width: 100%;
}

.c-field-of-action__innerimage {
  width: 10vw;
}