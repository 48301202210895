.c-cell {
  position: relative;

  &__copytext {
    @include default-cell-background();

    &--align-start {
      align-self: start;
    }
    &--2col {
      column-count: 2;
      column-gap: 1rem;
      break-inside: avoid-column;
    }
    &--3col {
      column-count: 3;
      column-gap: 1rem;
      break-inside: avoid-column;
      @include breakpoint(small only) {
        column-count: 1;
        column-gap: unset;
      }
    }
  }
  &__padding {
    @include default-cell-padding();
  }
}
