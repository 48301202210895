.l-content-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-template-areas:
   "header"
   "fact"
   "steering"
   "footer";
  align-content: end;
}