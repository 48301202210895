.c-image-container {
  position: relative;
}

.c-image {
  position: relative;
  object-fit: cover;
  width: 100%;
  //max-height: 100%;
  @include default-image-border(top);
  @include breakpoint(medium down) {
    max-height: 50%;
  }
}

.c-image-caption {
  @include default-cell-background(bottom);
  font-size: 0.8rem;
  text-align: center;
  @include breakpoint(small only) {
    margin: auto;
  }
}