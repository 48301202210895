/*.c-header {
  display: block;
  position: fixed;
  top: 0rem;
  right: 0rem;
  width: 8rem;
  height: 8rem;
  background-color: white;
  outline-color: red;
  outline-offset: 4px;
  border-bottom-left-radius: 100%;
  z-index: 9;
  box-shadow: rgba(#000, 0.25) 1rem 1rem 3rem;
  overflow: hidden;
  transition:
          border-radius 400ms ease-in,
          height 400ms ease-in 400ms,
          width 400ms ease-out 400ms;
  &:hover {
    border-radius: 0;
    width: 100vw;
    height: 66vh;
    & > .c-header__information {
      display: block;
      visibility: visible;
    }
  }
}*/

.c-header {
  display: flex;
  box-sizing: border-box;
  grid-area: header;
  width: 100%;
  position: sticky;
  top: 0;
  height: 6rem;
  z-index: 99;
  background-color: white;
  box-shadow: 0 .5rem 1rem rgba($secondary-color, .1);

  @include breakpoint(medium down) {
    height: 3.5rem;
  }
}

.c-header__logo {
  display: flex;
  justify-content: center;
  flex-basis: min(240px, 260px);
  align-self: center;

  @include breakpoint(medium down) {
    flex-basis: min(180px, 240px);
  }
}

.c-header__logo-image {
  height: 4.5rem;

  @include breakpoint(medium down) {
    height: 3rem;
  }
}

.c-header__navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 100%;

  & .n-login {
    margin-left: auto;
  }

  & .n-socialmedia--youtube {
    margin-right: 3rem;
    @include breakpoint (small only) {
      margin-right: 2rem;
    }
  }
}

.c-header__button {
  padding: .5rem;
  border-radius: 0.3rem;
  box-shadow: $secondary-color .1rem .1rem .5rem;
  margin-inline: auto;
  @include breakpoint(small only) {
    padding: .25rem;
    font-size: 0.9rem;
    line-height: 1.3;
    box-shadow: $secondary-color .05rem .05rem .4rem;
  }
}