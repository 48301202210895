.c-leadership__card {
  position: relative;
  margin-top: 1rem;
  @include default-cell-background(all);
}

.c-leadership__name {
  color: $secondary-color;
  text-align: center;
  font-weight: bold;
}

.c-leadership__title {
  text-align: center;
}
.c-leadership__symbol {
  margin-right: 0.5rem;
  color: $primary-color;
}