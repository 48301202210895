.c-testimonial {
  position: relative;
  box-shadow: silver .25rem .25rem .5rem;
  //align-self: start;
}

.c-testimonial__content-container {
  position: relative;
  padding: 4rem 0 4rem 0;
  background-image: url(../images/background/comfort-zone-fish-and-hand.png);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  @include breakpoint(small only) {
    background-size: contain;
    background-repeat: repeat-y;
    background-attachment: unset;
  }
}

.c-testimonial__image-container {
  align-self: start;
  position: relative;
  float: left;
  top: -2rem;
  left: -2rem;
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  box-shadow: silver .25rem .25rem .5rem;
  border-radius: 50%;
  shape-outside: circle(50% at 45% 35%);
}

.c-testimonial__image {

}

.c-testimonial__copytext {
  position: relative;
  isolation: isolate;
  display: inline;
  text-shadow: white .05rem .05rem;

  &::before {
    position: absolute;
    display: inline;
    content: '»';
    font-family: $header-font-family;
    font-size: 7em;
    left: 1.2em;
    top: -.5em;
    color: #ddd;
  }
}

.c-testimonial__author {
  text-align: right;
  font-size: 0.9rem;
  font-style: italic;
}